module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"k6","short_name":"k6","start_url":"/","background_color":"#fff","theme_color":"#BADA55","display":"minimal-ui","icon":"./src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b39a6f3e0dc925c8ec4f77e0a65490e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-relative-images","id":"46bd1896-257f-5076-b73b-7e4836aab9a7","name":"gatsby-remark-relative-images","version":"2.0.2","modulePath":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-relative-images/dist/index.js","module":{"defaultPluginOptions":{"staticFolderName":"static","include":[],"exclude":[]}},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.24.0","modulePath":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-images","id":"a843d00d-40f3-5f93-8f1b-f5f46a88712f","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/home/runner/work/k6-docs/k6-docs/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"quality":90,"withWebp":true,"disableBgImage":true,"wrapperStyle":"margin-left: 0;","showCaptions":["title"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/k6-docs/k6-docs","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":90,"withWebp":true,"disableBgImage":true,"wrapperStyle":"margin-left: 0;","showCaptions":["title"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KBV9L4L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f46b8e24a5374539ba179e52835913e3@o175050.ingest.sentry.io/5289132","environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
